import React from 'react';
import { isServer } from '@utils/isServer';
import AuthStore from './AuthStore';
import AdminStore from './AdminStore';
import SearchStore from './SearchStore';
import UIStore from './UIStore';
import ReservationStore from './ReservationStore';
import MyReservationStore from './MyReservationStore';

let clientSideStores;

export function getStores(initialData = { authStoreInitialData: {} }) {
  if (isServer) {
    return {
      uiStore: new UIStore(),
      authStore: new AuthStore(initialData.authStoreInitialData),
      adminStore: new AdminStore(),
      searchStore: new SearchStore(),
      reservationStore: new ReservationStore(),
      myReservationStore: new MyReservationStore(),
    };
  }
  if (!clientSideStores) {
    clientSideStores = {
      uiStore: new UIStore(),
      authStore: new AuthStore(initialData.authStoreInitialData),
      adminStore: new AdminStore(),
      searchStore: new SearchStore(),
      reservationStore: new ReservationStore(),
      myReservationStore: new MyReservationStore(),
    };
  }

  return clientSideStores;
}

const StoreContext = React.createContext();

export function StoreProvider(props) {
  return (
    <StoreContext.Provider value={props.value}>
      {props.children}
    </StoreContext.Provider>
  );
}

export function useMobxStores() {
  return React.useContext(StoreContext);
}
