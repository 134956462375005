import usePrefix from '@/hooks/usePrefix';
import { getLastPageVisited } from '@helpers/history';
import { useMobxStores } from '@stores/index';
import facebookAuthData from '@utils/facebookauth';
import googleAuthData, { GOOGLE_CALLBACK } from '@utils/googleauth';
import { Button, Form, Input } from 'antd';
import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import Link from 'next/link';
import Head from 'next/head';
import Router from 'next/router';
import { useEffect, useState } from 'react';
import { CDN2 } from '@/helpers/api';
import { GetServerSideProps } from 'next';
import { basicPageProps } from '@/utils/isServer';

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { airports } = await basicPageProps(ctx);

  return {
    props: {
      airports,
    },
  };
};

function Login() {
  const [form] = Form.useForm();
  const { authStore } = useMobxStores();
  const prefix = usePrefix();
  const [sendingForm, setSendingForm] = useState(false);

  useEffect(() => {
    if (authStore.signedIn) {
      Router.push(getLastPageVisited());
    }
    googleAuthData.handleClientLoad();
    facebookAuthData.load();
  }, [authStore]);

  function doLogin(email, password): void {
    authStore.doLogin(email, password);
    setSendingForm(false);
  }

  function doGoogleLogin() {
    googleAuthData.handleAuthClick();
  }

  function doFacebookLogin() {
    facebookAuthData.FBLogin('L', getLastPageVisited());
  }

  function handleSubmit(values) {
    setSendingForm(true);
    const { email, password } = values;
    doLogin(email, password);
  }
  return (
    <>
      <Head>
        <title>Sign In | On Air Parking</title>
        <meta name="description" content="Login Page" />
      </Head>
      <div className="container mx-auto px-4">
        <h2 className="text-3xl mx-auto text-center py-8">Sign In</h2>
        <Form
          className="max-w-md border mx-auto bg-gray-100 p-8"
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <div className="flex flex-col w-60 mx-auto justify-center items-center">
            <div
              className="flex flex-row bg-social-login-blue h-10 px-px text-center border border-social-login-blue rounded-full w-[15.238rem] cursor-pointer"
              onClick={doFacebookLogin}
            >
              <Image
                src={CDN2() + '/staticmyapp/icon-social-face-white.svg'}
                width={36}
                height={36}
                priority
                alt="Login with Facebook"
              />
              <p className="text-white self-center mx-auto">
                Sign in with Facebook
              </p>
            </div>
            <div className="flex-1 text-center mt-4" id="gSignin"></div>
          </div>
          <div className="relative flex flex-row w-100 mx-auto py-8 justify-center items-center text-center divider-text">
            <span className="relative z-10 bg-gray-100 px-4">
              or sign in
              <br />
              with email
            </span>
          </div>

          {/* <input type="hidden" name="origin" value="L+" /> */}

          <div className="flex flex-col">
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Not a valid email!',
                },
              ]}
              htmlFor="auth_email"
            >
              <Input
                type="email"
                className="rounded-full py-2 px-4"
                id="auth_email"
                inputMode="email"
              />
            </Form.Item>
          </div>

          <div className="flex flex-col">
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  type: 'string',
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              htmlFor="auth_password"
            >
              <Input
                type="password"
                className="rounded-full py-2 px-4"
                id="auth_password"
                inputMode="text"
              />
            </Form.Item>
            <p className="py-3 mb-4 text-secondary">
              <Link href={prefix + '/forgot'}>Forgot Password?</Link>
            </p>
          </div>

          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="block bg-primary rounded-full px-24 mx-auto align-center uppercase"
            id="lbtn-login"
            loading={sendingForm}
          >
            Login
          </Button>
        </Form>
        <div className="max-w-md mx-auto bg-secondary p-4 text-center text-white">
          <p>
            Don't have an account?{' '}
            <Link
              href={prefix + '/signup'}
              className="underline hover:text-white hover:underline"
            >
              Create account.
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}

export default observer(Login);
