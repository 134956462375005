import { NextRouter } from 'next/router';

export function setLastPageVisited(): void {
  const pageUrl = new URL(window.location.href);
  const ignoreList = [
    '/myapp/login',
    '/login',
    '/myapp/signup',
    '/signup',
    '/myapp/forgot',
    '/forgot',
    '/myapp/GoogleCallback',
    '/GoogleCallback',
  ];
  if (!ignoreList.includes(pageUrl.pathname))
    sessionStorage.setItem('lastPageVisited', window.location.href);
}

export function canonicalUrl(baseUrl: string, router: NextRouter): string {
  let pathName = router.asPath;
  if (pathName.indexOf('?') != -1) {
    pathName = pathName.split('?')[0];
  }
  return baseUrl + pathName;
}

export function getLastPageVisited(): string {
  let lastPage = sessionStorage.getItem('lastPageVisited');

  if (lastPage == null) {
    if (window.location.href.indexOf('myapp') != -1) {
      lastPage = '/myapp';
    } else {
      lastPage = '/';
    }
  }
  return lastPage;
}

