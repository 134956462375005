import { CDN2 } from '@/helpers/api';
import { setLastPageVisited } from '@/helpers/history';
import { Layout } from 'antd';
import { observer } from 'mobx-react-lite';
import Head from 'next/head';
import Image from 'next/image';
import React, { useEffect } from 'react';
import { LockOutlined } from '../Icons';
const { Content } = Layout;

function CheckoutLayout({ children, airports }) {
  useEffect(() => {
    setLastPageVisited();
  });

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <nav className="bg-white shadow-md">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <a href="/">
                  <Image
                    src={CDN2() + '/staticmyapp/logo-registred.svg'}
                    width="112"
                    height="56"
                    alt="On Air Parking"
                    priority
                  />
                </a>
              </div>
            </div>
            <div className="block ml-auto">
              <div className="ml-10 flex items-baseline space-x-8">
                <p className="font-bold">
                  <LockOutlined className="mr-1" />
                  Secure Checkout
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <Content className="site-layout max-w-5xl mx-auto">{children}</Content>
    </>
  );
}

export default observer(CheckoutLayout);
