import { fetchTemplate } from '@/utils/isServer';
import dayjs from 'dayjs';
import {
  IReservationsResponse,
  IReservationResponse,
  IPriceDescription,
  ICheckoutHelper,
  ICheckoutResponse,
  IReservationCodeResponse,
  IReservationReviewResponse,
  IMyFacilitiesResponse,
} from '../types';
export const loadData = async (): Promise<IReservationsResponse> => {
  const response = await fetch('/api/Reservation/All', {
    ...fetchTemplate,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  const data: IReservationsResponse = await response.json();
  data.result = data?.result?.map((item) => {
    item.dayjs_reservation_dt_checkin = dayjs(item.reservation_dt_checkin);
    item.dayjs_reservation_dt_checkout = dayjs(item.reservation_dt_checkout);
    return item;
  });
  return data;
};

export const loadNextReservation = async (
  nextPage?: string
): Promise<IReservationsResponse> => {
  const response = await fetch(
    `/api/Reservation/NextReservations?nextPage=${nextPage ? nextPage : ''}`,
    {
      ...fetchTemplate,
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
  const data: IReservationsResponse = await response.json();
  data.result = data?.result?.map((item) => {
    item.dayjs_reservation_dt_checkin = dayjs(item.reservation_dt_checkin);
    item.dayjs_reservation_dt_checkout = dayjs(item.reservation_dt_checkout);
    return item;
  });
  return data;
};

export const loadPreviousReservation = async (
  nextPage?: string
): Promise<IReservationsResponse> => {
  const response = await fetch(
    `/api/Reservation/PreviousReservations?nextPage=${
      nextPage ? nextPage : ''
    }`,
    {
      ...fetchTemplate,
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
  const data: IReservationsResponse = await response.json();
  data.result = data?.result?.map((item) => {
    item.dayjs_reservation_dt_checkin = dayjs(item.reservation_dt_checkin);
    item.dayjs_reservation_dt_checkout = dayjs(item.reservation_dt_checkout);
    return item;
  });
  return data;
};

export const loadOne = async (id: number): Promise<IReservationResponse> => {
  const response = await fetch(`/api/Reservation/Data/${id}`, {
    ...fetchTemplate,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  const data: IReservationResponse = await response.json();
  if (data.result) {
    data.result = {
      ...data.result,
      dayjs_reservation_dt_checkin: dayjs(data.result.reservation_dt_checkin),
      dayjs_reservation_dt_checkout: dayjs(data.result.reservation_dt_checkout),
    };
  }
  if (data.gracePeriod) {
    data.dayjs_gracePeriod = dayjs(data.gracePeriod);
  }
  return data;
};

export const loadByCode = async (
  id: string,
  urlBase?: string
): Promise<IReservationCodeResponse> => {
  const response = await fetch(`${urlBase ?? ''}/api/Reservation/Code/${id}`, {
    ...fetchTemplate,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data: IReservationCodeResponse = await response.json();
  if (data.result) {
    data.result = {
      ...data.result,
      dayjs_reservation_dt_checkin: dayjs(data.result.reservation_dt_checkin),
      dayjs_reservation_dt_checkout: dayjs(data.result.reservation_dt_checkout),
    };
  }
  return data;
};
export const calculateReservationPrice = async (
  reservation: ICheckoutHelper
): Promise<IPriceDescription> => {
  try {
    const response = await fetch(`/api/Reservation/CalculatePrice`, {
      ...fetchTemplate,
      method: 'POST',
      body: JSON.stringify({
        reservation_id: reservation.reservation_id,
        reservation_dt_checkin: reservation.dayjs_reservation_dt_checkin.format(
          'YYYY-MM-DDTHH:mm:ss'
        ),
        reservation_dt_checkout:
          reservation.dayjs_reservation_dt_checkout.format(
            'YYYY-MM-DDTHH:mm:ss'
          ),
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data: IPriceDescription = await response.json();
    return data;
  } catch (e) {
    return null;
  }
};

export const sendReceipts = async (
  reservation: ICheckoutHelper
): Promise<any> => {
  try {
    const response = await fetch(
      `/api/Reservation/ResendReceipt/${reservation.reservation_id}`,
      {
        ...fetchTemplate,
        method: 'POST',
        body: JSON.stringify({}),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const data: any = await response.json();
    return data;
  } catch (e) {
    return null;
  }
};

export const updateReservation = async (
  reservation: ICheckoutHelper
): Promise<[Response, ICheckoutResponse]> => {
  const response = await fetch(`/api/Reservation/Update`, {
    ...fetchTemplate,
    method: 'POST',
    body: JSON.stringify({
      payment_flow: reservation.payment_flow,
      stripeToken: reservation.stripeToken,
      member_id: reservation.member_id,
      reservation_id: reservation.reservation_id,
      observation: reservation.observation,
      reservation_dt_checkin: reservation.dayjs_reservation_dt_checkin.format(
        'YYYY-MM-DDTHH:mm:ss'
      ),
      reservation_dt_checkout: reservation.dayjs_reservation_dt_checkout.format(
        'YYYY-MM-DDTHH:mm:ss'
      ),
      card_use: reservation.card_use,
      save_card_number: reservation.save_card_number,
      reservation_zipcode: reservation.reservation_zipcode,
      card_brand: reservation.card_brand,
      card_country: reservation.card_country,
      card_last4: reservation.card_last4,
      exp_month: reservation.exp_month,
      exp_year: reservation.exp_year,
      oneTimeToken: reservation.oneTimeToken,
      client_secret: reservation.client_secret,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data: ICheckoutResponse = await response.json();
  return [response, data];
};

export const updatePlateReservation = async (reservation: {
  member_id: number;
  reservation_id: number;
  license_plate: string;
}): Promise<[Response, ICheckoutResponse]> => {
  const response = await fetch(`/api/Reservation/UpdatePlate`, {
    ...fetchTemplate,
    method: 'PUT',
    body: JSON.stringify({
      member_id: reservation.member_id,
      reservation_id: reservation.reservation_id,
      license_plate: reservation.license_plate,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data: ICheckoutResponse = await response.json();
  return [response, data];
};

export const deleteReservation = async (
  reservation: ICheckoutHelper
): Promise<ICheckoutResponse> => {
  const response = await fetch(`/api/Reservation/Cancel`, {
    ...fetchTemplate,
    method: 'POST',
    body: JSON.stringify({
      member_id: reservation.member_id,
      reservation_id: reservation.reservation_id,
      observation: reservation.observation,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data: ICheckoutResponse = await response.json();
  return data;
};

export const deleteRebookReservation = async (
  reservation: ICheckoutHelper
): Promise<ICheckoutResponse> => {
  const response = await fetch(`/api/Reservation/RebookStart`, {
    ...fetchTemplate,
    method: 'POST',
    body: JSON.stringify({
      member_id: reservation.member_id,
      reservation_id: reservation.reservation_id,
      observation: reservation.observation,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data: ICheckoutResponse = await response.json();
  return data;
};

export const deleteCancelRebookReservation = async (
  reservation: ICheckoutHelper
): Promise<ICheckoutResponse> => {
  const response = await fetch(`/api/Reservation/RebookCancel`, {
    ...fetchTemplate,
    method: 'POST',
    body: JSON.stringify({
      member_id: reservation.member_id,
      reservation_id: reservation.reservation_id,
      observation: reservation.observation,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data: ICheckoutResponse = await response.json();
  return data;
};

export const getReservationReview = async (
  id: number
): Promise<IReservationReviewResponse> => {
  const response = await fetch(`/api/ReservationReview/${id}`, {
    ...fetchTemplate,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data: IReservationReviewResponse = await response.json();
  if (data?.count && data?.result) {
    data.result.dayjs_reservation_dt_checkin = dayjs(
      data.result.reservation_dt_checkin
    );
    data.result.dayjs_reservation_dt_checkout = dayjs(
      data.result.reservation_dt_checkout
    );
  }
  return data;
};

export const doLoadMyFacilities = async (): Promise<IMyFacilitiesResponse> => {
  const response = await fetch(`/api/Member/MyFacilities`, {
    ...fetchTemplate,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data: IMyFacilitiesResponse = await response.json();
  return data;
};

