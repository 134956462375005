export const groupDatesByRate = (datePricesList) => {
  const groupedDates = [];
  datePricesList.forEach((item) => {
    if (groupedDates.length === 0) {
      groupedDates.push({
        start: item.date,
        end: item.date,
        rate: item.rate,
        hours: item.hours,
        days: 1,
        free: item.free,
      });
      return;
    }

    if (
      item.rate === groupedDates[groupedDates.length - 1].rate &&
      !item.free
    ) {
      const last = groupedDates[groupedDates.length - 1];
      last.end = item.date;
      last.days = last.days + 1;
      groupedDates[groupedDates.length - 1] = last;
      return;
    }

    groupedDates.push({
      start: item.date,
      end: item.date,
      rate: item.rate,
      hours: item.hours,
      days: 1,
      free: item.free,
    });
  });

  return groupedDates;
};

