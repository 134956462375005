import { absoluteServerPath, resolveConfigUrl } from '@/helpers/api';
import { cacheFetch } from '@/helpers/cacheFetch';

export const isServer = typeof window === 'undefined';

export const fetchTemplate = {
  credentials: 'same-origin',
} as RequestInit;

export const mobileProps = (ctx) => {
  const UA = ctx?.req?.headers['user-agent'] ?? '';
  const isMobile = Boolean(
    UA.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );
  const isIos = Boolean(UA.match(/iPhone|iPad|iPod/i));
  return { isMobile, isIos };
};

export const basicPageProps = async (ctx) => {
  const protocol = ctx?.req?.headers['x-forwarded-proto'] || 'http';
  const baseUrl = ctx?.req ? `${protocol}://${ctx?.req?.headers?.host}` : '';

  const configInfo = await cacheFetch(resolveConfigUrl(baseUrl));

  const airports = await cacheFetch(
    `${absoluteServerPath(configInfo)}/api/Airport`
  );
  return { protocol, baseUrl, configInfo, airports };
};

const botPattern =
  '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';
const botRE = new RegExp(botPattern, 'i');
export const isCrawler = (userAgent) => {
  return botRE.test(userAgent);
};

export function getAirportLocalStorage(
  searchType,
  setAirportValue,
  airportValue
) {
  if (
    !isServer &&
    searchType == 'airport' &&
    localStorage.getItem('savedAirport')
  ) {
    try {
      const data = JSON.parse(localStorage.getItem('savedAirport'));
      if (setAirportValue) {
        if (airportValue?.value != data.value) {
          setAirportValue(data);
        }
      }
      return data.value;
    } catch (e) {
      console.log(e);
      return null;
    }
  }
  return null;
}

export function setAirportLocalStorage(searchType, airportData) {
  if (!isServer && searchType == 'airport') {
    localStorage.setItem('savedAirport', JSON.stringify(airportData));
  }
}
