import { CDN_ENABLED } from '@/utils/cdn_enabled';

export const absoluteServerPath = (configInfo) => {
  if (process.env.FORCE_API_ADDRESS) {
    return process.env.FORCE_API_ADDRESS;
  }
  if (configInfo?.isSandBoxEnvironment)
    return 'https://s4ndb0xqa.offairpark.com';
  if (configInfo?.isProductionEnvironment) return 'https://onairparking.com';
  if (configInfo?.environment === 0) return 'http://localhost:3000';
};

export const resolveConfigUrl = (baseUrl) => {
  if (process.env.FORCE_API_ADDRESS) {
    return `${process.env.FORCE_API_ADDRESS}/api/Info`;
  }
  return `${
    baseUrl.includes('localhost') ? baseUrl.replace(':3000', '') : baseUrl
  }/api/Info`;
};

export const CDN1 = 'https://d1u6k6nu5jeiha.cloudfront.net/images';

export const CDN2 = () =>
  CDN_ENABLED ? 'https://d1u6k6nu5jeiha.cloudfront.net/images' : '';
