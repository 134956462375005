import { getLastPageVisited } from '@helpers/history';

// https://developers.google.com/identity/gsi/web/reference/js-reference
// https://developers.google.com/identity/gsi/web/guides/migration#redirect-mode_1

// declare let gapi: any;
declare let google: any;

interface GooglePerm {
  apiKey: string;
  clientId: string;
}

export const GOOGLE_CALLBACK: string =
  process.env.NODE_ENV === 'production'
    ? '/GoogleCallback'
    : '/myapp/GoogleCallback';

class GoogleAuth {
  google_perm = {
    apiKey: 'AIzaSyDqn89rptN6rnsPGFDLWsbmI6KoDD57opk', //'AIzaSyDqn89rptN6rnsPGFDLWsbmI6KoDD57opk',
    clientId:
      '432842062404-09cih475q92krsd1r315qfasgq26fov7.apps.googleusercontent.com',
  };
  /*
  google_perm: GooglePerm = {
    apiKey: 'AIzaSyDqn89rptN6rnsPGFDLWsbmI6KoDD57opk', //'AIzaSyDqn89rptN6rnsPGFDLWsbmI6KoDD57opk',
    clientId:
      '432842062404-09cih475q92krsd1r315qfasgq26fov7.apps.googleusercontent.com',
  };

  google_perm: GooglePerm = {
    apiKey: 'S6rr4C2tAX7Ff8DXWWCTaZXF',
    clientId:
      '714814673722-s5upt876kgvkhi3v1aep220p9b1s182i.apps.googleusercontent.com',
  };
*/
  hostBack: any;

  GoogleAuth: any;

  type: string;
  tries: number;

  handleClientLoad() {
    this.hostBack =
      window.location.protocol + '//' + window.location.host + GOOGLE_CALLBACK; //+
    // '?state=' +
    // encodeURIComponent(window.location.pathname);
    if (typeof google !== 'undefined') {
      let red = getLastPageVisited();
      const pos = red.indexOf('/', 9);
      red = red.substring(pos);
      google.accounts.id.initialize({
        client_id: this.google_perm.clientId,
        login_uri: this.hostBack,
        ux_mode: 'redirect',
        enable_redirect_uri_validation: true,
        state: encodeURIComponent(red),
      });
      google.accounts.id.renderButton(document.getElementById('gSignin'), {
        theme: 'filled_blue',
        size: 'large',
        type: 'standard',
        text: 'signin_with',
        width: 244,
        shape: 'circle',
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      if (this.tries > 3) {
        return;
      }
      setTimeout(function () {
        that.handleClientLoad();
        that.tries++;
      }, 100);
    }
  }

  doLogout() {}

  logoutDone() {
    localStorage.clear();

    window.location.href = getLastPageVisited();
  }

  updateSigninStatus(isSignedIn) {
    console.log('updateSigninStatus => isSignedIn: ' + isSignedIn);
    this.setSigninStatus(isSignedIn, '');
  }

  initClient() {}

  handleAuthClick() {}

  revokeAccess() {}

  setSigninStatus(jwt, state) {
    const params = {
      email: jwt.email,
      firstname: jwt.given_name,
      gender: '',
      lastname: jwt.family_name,
      location: '',
      origin: 'G+',
      rememberme: false,
    };

    const data = `email=${encodeURIComponent(
      jwt.email
    )}&lastname=${encodeURIComponent(
      jwt.given_name
    )}&firstname=${encodeURIComponent(
      jwt.family_name
    )}&origin=G%2B&rememberme=0&gender=&location=`;

    const request = fetch('/Login', {
      method: 'POST',
      headers: {
        //'Content-Type': 'application/json'
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data,
    });
    request
      .then((result) => {
        result.json().then((result) => {
          localStorage.setItem('google_auth', 'on');
          this.handle_login(result, state);
        });
      })
      .catch((b) => {
        console.log(b.responseText);
      });

    console.log(
      'You are currently signed in and have granted access to this app.'
    );
  }
  mapUrl(url) {
    if (url === '/reservation/citysearch') return '/city';
    return url || '/myapp';
  }
  handle_login(result, state) {
    console.log(this.type);
    console.log(result);
    if (result.member_token) {
      localStorage.setItem('login_token', result.member_token);
    }
    if (result.jwt) {
      sessionStorage.setItem('jwt', result.jwt);
    }
    if (this.type === 'B') {
      const redir = this.mapUrl(state);
      window.location.href = redir;
    } else if (this.type === 'L') {
      window.location.href = getLastPageVisited();
      //window.location.href = this.mapUrl(window.location.pathname);
    } else if (this.type === 'M') {
      window.location.href = getLastPageVisited();
      //                window.location.href = "/myreservation/reservation/" + $("#reservation").val();
    } else {
      window.location.href = getLastPageVisited();
    }
  }

  finish(jwt, state) {
    this.type = 'B';
    this.setSigninStatus(jwt, state);
  }

  login_logout(jwt, state) {
    if (window.location.pathname.indexOf('/GoogleCallback') !== -1) {
      this.finish(jwt, state);
    }
  }
}

const googleAuthData = new GoogleAuth();

export default googleAuthData;

