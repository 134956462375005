declare let FB;
declare let document;

class FacebookAuth {
  //appId = '630070280699658'; //tst
  appId:string = '279709909612688'; // prod
  /*********************FACEBOOK**********************/

  load() {
    window['fbAsyncInit'] = () => {
      FB.init({
        appId: this.appId,
        xfbml: true,
        version: 'v2.11',
      });
      FB.AppEvents.logPageView();
    };

    // Load the SDK asynchronously
    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.async = true;
      js.defer = true;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }
  /*
    FLogin = function () {
        $("[class*='lfacebook']").click(function () {
            $("#signintext").html("Authenticating....");
            FBLogin("L");
            $("#signintext").html("Sign In");
        });

        $("#cfacebook").click(function () {
            FBLogin("C");
        });

        $("#mfacebook").click(function () {
            FBLogin("M");
        });
    };
    var FLogout = function () {

        $("#logout").click(function () {
            FBLogout("L");
        });

        $("#ctfacebook").click(function () {
            FBLogout("C");
        });
    };
*/

  FBLogin(type, lastPageVisited = '/') {
    FB.login(
      (response) => {
        if (response.authResponse) {
          console.log('Welcome!  Fetching your information.... ');
          FB.api('/me?fields=name,email', function (response) {
            //console.log('Good to see you, ' + response.email + '.');

            const data = `email=${encodeURIComponent(
              response.email
            )}&firstname=${encodeURIComponent(
              response.name
            )}&origin=F%2B&rememberme=0`;

            const request = fetch('/Login', {
              method: 'POST',
              headers: {
                //'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: data,
            });
            request
              .then((result) => {
                result.json().then((result) => {
                  localStorage.setItem('login_token', result.member_token);
                  sessionStorage.setItem('jwt', result.jwt);
                  if (type === 'L') window.location.href = lastPageVisited;
                  //location.reload();
                });
              })
              .catch((b) => {
                console.log(b.responseText);
              });
          });
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      { scope: 'email,public_profile' }
    );
  }

  FBLogout(type) {
    FB.logout(function (response) {
      //window.location.href = "/";
      location.reload();
    });
  }
}

const faceAuthData = new FacebookAuth();

export default faceAuthData;
