import { makeAutoObservable } from 'mobx';
import {
  IChartData,
  IChartDataResponse,
  IFacility,
  IPaymentHistoryResponse,
} from '@/types';
import adminService from '@/services/adminService';
import { Dayjs } from 'dayjs';

class AdminStore {
  chartDataResponse: IChartDataResponse = null;
  facilityList: IFacility[] = [];
  constructor(initialData: any = {}) {
    makeAutoObservable(this);
  }

  async loadChartData() {
    if (this.chartDataResponse !== null) return;
    const chartDataResponse = await adminService.loadChartData();
    this.chartDataResponse = chartDataResponse;
    this.facilityList = chartDataResponse.result.map((a) => {
      return {
        facility_id: a.facility_id,
        facility_name: a.facility_name,
      } as IFacility;
    });
  }

  getFacility(facility_id: number): IChartData {
    return this.chartDataResponse?.result?.find(
      (x) => x.facility_id == facility_id
    );
  }

  async loadPaymentHistory(
    facilityId: number
  ): Promise<IPaymentHistoryResponse> {
    return await adminService.loadPaymentHistory(facilityId);
  }
  async searchHistoryChart(
    facility_id: number,
    type: string,
    dt_checkin: Dayjs,
    dt_checkout: Dayjs
  ): Promise<IChartDataResponse> {
    let ntype = 0;
    if (type == 'week') ntype = 1;
    if (type == 'month') ntype = 2;
    if (type == 'year') ntype = 3;
    const shResponse = await adminService.searchHistoryChart(
      facility_id,
      ntype,
      dt_checkin,
      dt_checkout
    );
    return shResponse;
  }
}

export default AdminStore;
