import { makeAutoObservable } from 'mobx';
import Router from 'next/router';
import { fetchTemplate, isServer } from '@utils/isServer';
import { modalType } from '@components/Modal';
import { getLastPageVisited } from '@helpers/history';

export interface IAuthUser {
  exp: number;
  member_login: string;
  member_id: number;
  member_phone: string;
  member_first_name: string;
  member_last_name: string;
  member_zip: string;
  member_dt_next_payment: string;
  user_level: number;
  coupon_member: string;
}

class AuthStore {
  token: string;
  user: IAuthUser;
  signedIn = false;

  loginRequested = false;
  forgottenPasswordEmailRequested = false;
  signupRequested = false;

  constructor(initialData: any = { token: null, user: null }) {
    this.token =
      initialData.token || (!isServer && sessionStorage.getItem('jwt'));
    this.signedIn = this.token !== null && this.token.length > 0;
    this.user = initialData.user || this.parseJwt();
    makeAutoObservable(this);
  }

  parseJwt() {
    try {
      return JSON.parse(!isServer && window.atob(this.token.split('.')[1]));
    } catch (e) {
      return null;
    }
  }

  get fullName() {
    return `${this.user.member_first_name} ${this.user.member_last_name}`;
  }

  isSignedIn() {
    return this.signedIn;
  }

  setSignedIn(value) {
    this.signedIn = value;
  }

  getReviewUser() {
    let rname = this.user?.member_first_name;
    if (this.user?.member_last_name) {
      rname += ' ' + this.user?.member_last_name?.charAt(0) + '.';
    }
    return rname;
  }

  async doResetPassword(email, id, newPassword) {
    try {
      const data = `member_email=${encodeURIComponent(
        email
      )}&recover_password_token=${id}&member_password=${newPassword}`;

      const response = await fetch('/api/Member/CheckResetPassword', {
        ...fetchTemplate,
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data,
      });

      return await response.json();
    } catch (err) {
      return { Message: 'Could not complete Reset.' };
    }
  }
  async doLogin(email, password) {
    try {
      this.loginRequested = true;

      const data = `email=${encodeURIComponent(
        email
      )}&password=${encodeURIComponent(password)}&origin=L%2B&rememberme=0`;

      const response = await fetch('/Login', {
        ...fetchTemplate,
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data,
      });

      const result = await response.json();

      if (!result || !result.jwt || result.jwt.length === 0) {
        modalType({
          type: 'error',
          title: 'Error',
          content: 'Invalid username or password',
        });
      } else {
        this.registerToken(result.jwt);
        Router.push(getLastPageVisited());
      }
    } catch (err) {
      console.log(err);
    }

    this.loginRequested = false;
  }

  async doAutoLogin(loginToken) {
    try {
      this.loginRequested = true;

      const data = `token=${encodeURIComponent(
        loginToken
      )}&origin=T%2B&rememberme=0`;

      const response = await fetch('/Login', {
        ...fetchTemplate,
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data,
      });

      const result = await response.json();

      if (!result || !result.jwt || result.jwt.length === 0) {
        modalType({
          type: 'error',
          title: 'Error',
          content: 'Invalid Token',
        });
      } else {
        this.registerToken(result.jwt);
      }
    } catch (err) {
      console.log(err);
    }

    this.loginRequested = false;
  }
  assignJWT(jwt: string) {
    sessionStorage.setItem('jwt', jwt);
    this.setToken(jwt);

    const userData = this.parseJwt();
    this.setUser(userData);

    this.setSignedIn(true);
  }

  registerToken(jwt: string) {
    sessionStorage.setItem('jwt', jwt);
    this.setToken(jwt);

    const userData = this.parseJwt();
    this.setUser(userData);
    this.setSignedIn(true);
  }

  setToken(token) {
    this.token = token;
  }

  setUser(user) {
    this.user = user;
  }

  // Returns the member id for the given email, or 0 if not found
  async doForgot(email: string): Promise<Number> {
    this.forgottenPasswordEmailRequested = true;
    try {
      const data = `member_email=${encodeURIComponent(email)}`;

      const response = await fetch('/api/Member/NewForgot', {
        ...fetchTemplate,
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data,
      });

      const result: { member_id: number } = await response.json();
      this.forgottenPasswordEmailRequested = false;
      return result.member_id;
    } catch (err) {
      console.log(err);
      this.forgottenPasswordEmailRequested = false;
      return -1;
    }
  }

  // Returns the member id for the given email, or 0 if not found
  async doSignup(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    recaptchaToken: string
  ): Promise<any> {
    this.signupRequested = true;
    try {
      const data = `member_email=${encodeURIComponent(
        email
      )}&member_first_name=${firstName}&member_last_name=${lastName}&member_password=${password}&recaptcha_token=${recaptchaToken}`;

      const response = await fetch('/api/Member/CreateAccount', {
        ...fetchTemplate,
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data,
      });

      this.signupRequested = false;
      return await response.json();
    } catch (err) {
      console.log(err);
      this.signupRequested = false;
      return { Message: 'Could not complete signup.' };
    }
  }

  __data() {
    return {
      token: this.token,
      user: this.user,
    };
  }
}

export default AuthStore;
